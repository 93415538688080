import React from 'react'
import UserCreativeDashboard from '../../Components/UserDashboard/UserCreativeDashboard'

const UserCreativeDash = () => {
  return (
    <div>
      <UserCreativeDashboard />
    </div>
  )
}

export default UserCreativeDash
