import React from 'react'
import UserFavorite from '../../Components/UserDashboard/UserFavorite'

const UserFavoriteDash = () => {
  return (
    <div>
      <UserFavorite />
    </div>
  )
}

export default UserFavoriteDash
