import React from 'react'
import UserChat from '../../Components/UserDashboard/UserChat'

const UserChatDash = () => {
  return (
    <div>
      <UserChat />
    </div>
  )
}

export default UserChatDash
