import React from 'react'
import UserProfileDasboard from '../../Components/UserDashboard/UserProfileDasboard'

const UserProfile = () => {
  return (
    <div>
      <UserProfileDasboard />
    </div>
  )
}

export default UserProfile
