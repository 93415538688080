import React from 'react'
import LoginCom from '../../Components/AuthCom/LoginCom'

const Login = () => {
  return (
    <div>
      <LoginCom />
    </div>
  )
}

export default Login