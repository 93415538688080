import React from 'react'
import CreatingProfileUpdateDashboard from '../../Components/CreativeDashboard/CreatingProfileUpdateDashboard'

const CreativeProfileUpdataDash = () => {
  return (
    <div>
      <CreatingProfileUpdateDashboard />
    </div>
  )
}

export default CreativeProfileUpdataDash
