import React from 'react'
import UserHomeDashboard from '../../Components/UserDashboard/UserHomeDashboard'
import UserNavbar from '../../Components/UserDashboard/UserNavbar'

const UserHomeDash = () => {
  return (
    <div>
        <UserHomeDashboard />
    </div>
  )
}

export default UserHomeDash