import React from 'react'
import OTPCom from '../../Components/AuthCom/OTPCom'

const OTP = () => {
  return (
    <div>
        <OTPCom />
    </div>
  )
}

export default OTP