import React from 'react'
import gif from './creve.gif'
const MyLoader = () => {
  return (
    <div className='flex justify-center m-auto h-[40vh] items-center'>
      <span class="loader4"></span>
    </div>
  )
}

export default MyLoader
