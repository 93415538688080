import React from 'react'
import CreativeProfileDashboard from '../../Components/CreativeDashboard/CreativeProfileDashboard'

const CreativeProfileDash = () => {
  return (
    <div>
      <CreativeProfileDashboard />
    </div>
  )
}

export default CreativeProfileDash
