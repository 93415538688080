import React from 'react'
import RegisterCom from '../../Components/AuthCom/RegisterCom'

const Register = () => {
  return (
    <div>
        <RegisterCom />
    </div>
  )
}

export default Register