import React from 'react'
import SingleUserCreativeDash from '../../Components/UserDashboard/SingleUserCreativeDash'
import UserNavbar from '../../Components/UserDashboard/UserNavbar'

const SingleUserCreative = () => {
  return (
    <div>
      <SingleUserCreativeDash />
    </div>
  )
}

export default SingleUserCreative
