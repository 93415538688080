import React from 'react'
import CreativeBookingsDashboard from '../../Components/CreativeDashboard/CreativeBookingsDashboard'

const CreativeBookingsDash = () => {
  return (
    <div>
      <CreativeBookingsDashboard />
    </div>
  )
}

export default CreativeBookingsDash
