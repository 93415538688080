import React from 'react'
import CreaiveHomeDash from './CreaiveHomeDash'
import CreativeFAQs from '../../Components/CreativeDashboard/CreativeFAQs'

const CreativeFAQsDash = () => {
  return (
    <div>
      <CreativeFAQs />
    </div>
  )
}

export default CreativeFAQsDash
